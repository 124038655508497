//basic flex class
.flex-1 {
  flex: 1;
}

.color-primary {
  color: var(--primary-color);
}

.color-error {
  color: var(--color-error);
}

.color-warning {
  color: var(--color-warn);
}

.color-success {
  color: var(--color-success);
}

.color-disabled {
  color: var(--gray-800);
}

.scroll-area {
  overflow: auto;
  scrollbar-gutter: both-edges;
  //padding-bottom: 3rem;
}

.no-wrap {
  white-space: nowrap;
}

.mobile-width {
  width: 300px;
}

a {
  color: var(--font-color);
  text-decoration: none;

  &:visited {
    color: var(--font-color);
  }

  &.p-button {
    text-decoration: none;
    color: #fff;
    font-style: normal;
  }
}

p {
  a {
    text-decoration: underline;
    font-style: italic;
  }
}

.white-space-pre-line {
  white-space: pre-line;
}

.margin-center-horizontally {
  margin: 0 auto;
}

//adds pointer to all angular links
[routerlink] {
  cursor: pointer;
}

//scrollbar styling
/* width */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bebebe;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #989898;
}

::-webkit-scrollbar-button {
  background: #bebebe;
}

::-webkit-scrollbar-button:horizontal {
  width: 0.1rem;
  height: 0.5rem;
}

::-webkit-scrollbar-button:vertical {
  width: 0.5rem;
  height: 0.1rem;
}

.custom-dialog {
  margin: 0 1rem;
  max-width: 768px;

  &.fixed-height {
    max-height: 90vh;
    height: 768px;
  }

  &.full-height {
    height: 100%;
  }

  &.full-screen {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    display: flex;
    height: auto;
    max-width: none;
    max-height: none;
    padding: 0;
    margin: 0;

    .p-dialog-header {
      display: none;
    }

    .p-dialog-content {
      padding: 0;
    }
  }

  &.p-dialog > .p-dialog-content {
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > * {
      flex: 1;
      overflow: hidden;
      display: flex;
    }

    kfd-dialog-layout {
      display: flex;
      flex-direction: column;
      max-height: 100%;
      max-width: 100%;
      flex: 1;
    }
  }
}

//table
table {
  margin: 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
  border-collapse: collapse;

  &.no-border {
    box-shadow: none;
  }

  &.width-auto {
    width: auto;
  }

  &.no-margin-x {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  &.no-margin-y {
    margin-top: -0.2rem;
    margin-bottom: -0.2rem;
  }

  .center {
    text-align: center;
  }

  td {
    padding: 0.2rem 0.5rem;
  }

  thead {
    tr {
      background: var(--secondary-color);
      color: var(--text-color-secondary);
    }
  }

  tbody {
    tr {
      display: table-row;

      &:nth-of-type(odd) {
        background: var(--gray-200);
      }
    }
  }
}

.full-width {
  width: 100%;
}

.content-limiter {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  overflow: hidden;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
