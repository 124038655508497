.p-flex-1 {
  flex: 1;
}

p-button {
  &.flex-btn {
    display: flex;

    button {
      flex: 1;
    }
  }
}

//.p-button {
//  @media only screen and (max-width: 768px) {
//    &.p-button-rounded {
//      .p-button-icon-left {
//        margin: 0;
//      }
//
//      .p-button-label {
//        display: none;
//      }
//    }
//  }
//
//  &.p-button-text {
//    &.p-button-inline-text {
//      padding: 0;
//      width: auto;
//    }
//  }
//}

p-colorPicker {
  .p-colorpicker-preview {
    width: 100% !important;
    height: 100% !important;
  }
}

.p-dynamic-dialog {
  max-width: 1000px;
  width: 90%;

  .p-dialog-content {
    overflow: visible;
  }
}

.p-menu,
.p-contextmenu {
  .p-menuitem-link {
    &.p-menuitem-link-active {
      background: var(--primary-color);
    }
  }

  .p-menuitem-badge {
    border: 1px solid orange;
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
    text-align: center;
    margin-left: 0.5rem;
  }
}

//FIXES

//fixes dropdown with in input group --> cannot be used destroys other groups
//.p-inputgroup .p-inputwrapper,
//.p-inputgroup .p-inputwrapper > .p-component,
//.p-fluid .p-inputgroup .p-inputtext {
//  width: auto;
//}

.p-inputgroup .auto-width {
  width: auto !important;
}

//
//.p-inputgroup {
//  // class for custom elements in input groups
//  .p-inputgroup-item {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//  }
//}
//
//.p-inputgroup p-dropdown {
//  display: flex;
//  align-items: center;
//  justify-content: center;
//}
//
//.p-inputgroup .p-inputtext,
//.p-fluid .p-inputgroup .p-inputtext,
//.p-inputgroup .p-inputwrapper,
//.p-inputgroup .p-inputwrapper > .p-component {
//  flex: 1 1 auto;
//  width: 1%;
//}

//show toasts over modals
.p-toast {
  z-index: 1301 !important;
}
