// additional variables (not in theme)
:root {
  --white: #ffffff;
  --white-200: rgba(255, 255, 255, 0.2);

  --secondary-color: #5c5c5c;
  --secondary-color-dark: #525252;
  --secondary-color-light: #717171;
  --secondary-color-lighter: #a4a4a4;

  --text-color-primary: #ffffff;

  --ci-color-1: #48a4b6;
  --ci-color-2: #49a988;
  --ci-color-3: #e4a24c;

  --disabled-color: var(--surface-500);

  --color-dark: #495057;
  --color-success: #439446;
  --color-success-light: #c8e6c9;
  --color-error: #f44336;
  --color-error-light: #ffcdd2;
  --color-warn: #fbc02d;
  --color-warn-light: #ffecb3;
  --color-info: #0891cf;
  --color-info-light: #b3e5fc;
}

@import 'node_modules/pretty-print-json/dist/css/pretty-print-json.css';
@import 'prime_theme';
@import 'prime_overwrites';
@import 'keyframes';
@import 'loading-indicator';
@import 'icon-font';
@import 'styles';
