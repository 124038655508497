/* You can add global styles to this file, and also import other style files */

@import 'styles/prime';
//@import '../../../libs/web-core/src/lib/styles/index';

@import "../../../node_modules/jsoneditor/dist/jsoneditor.min.css";


body {
  margin: 0;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  font-family: var(--font-family);
  font-size: var(--font-size);
  //background: var(--gray-100);
  //background: var(--gray-200);
  background: var(--gray-50);
  //background: var(--white);
  --max-page-width: 1920px;
  --color-border: var(--gray-400);
}

h1 {
  font-size: 1.6rem;
  color: var(--color-dark);
  margin-bottom: 0;
}

h2 {
  font-size: 1.4rem;
  color: var(--color-dark);
  margin-bottom: 0;
}

a {
  color: var(--font-color);
  text-decoration: none;
}

p {
  margin-top: 0.25rem;
  margin-bottom: 0;
}

[ng-reflect-router-link],
[routerlink] {
  cursor: pointer;
}

pre {
  overflow: auto;
  border: 1px solid var(--color-border);

  code {
    margin: 0 1rem;
    display: block;
    font-size: 0.8rem;
    font-family: monospace;
  }
}

//.page-limiter {
//  display: flex;
//  flex: 1;
//  width: 100%;
//  max-width: 1920px;
//  //margin: 0 auto;
//}

.page-limiter {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  //max-width: var(--max-page-width);
  //margin: 0 auto;
}

.page-padding-x {
  padding: 0 1rem;
}

.page-toolbar {
  background: var(--secondary-color);
  color: var(--text-color-primary);
}

.p-dialog.full-screen-dialog {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: auto;
  display: flex;
  height: auto;
  max-width: none;
  max-height: none;

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    padding: 0;
  }
}

.highlight {
  background: var(--color-error-light);
  box-shadow: 0 0 0 0 var(--color-error-light);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.drag-element {
  transition: all 300ms ease-out;

  &.is-dragging {
    //position: absolute;
    box-shadow: 0 0 2px var(--primary-color);
    z-index: 1000;
    background: var(--white);
    opacity: 0.9;
    transition: transform 50ms ease-out;
    pointer-events: none;
  }
}

.vertical-line {
  border-left: 1px solid var(--color-border);
  margin: 0 0.5rem;
  align-self: stretch;
}

.horizontal-line {
  border-top: 1px solid var(--color-border);
  margin: 0.5rem 0;
  align-self: stretch;
}

.setting-label {
  font-weight: lighter;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.p-field {
  .setting-label:first-child {
    margin-top: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--color-error-light);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.tag-list {
  display: block;

  p-tag + p-tag {
    margin-left: 0.25rem;
  }
}
