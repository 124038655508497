/* You can add global styles to this file, and also import other style files */
@use 'styles/font';
//@use 'styles/prime';
@use 'styles/material-icons';

@use "../../../node_modules/jsoneditor/dist/jsoneditor.min.css";
//@use "../../../libs/web-core/src/lib/styles/index";
@use "../../../libs/web-core/src/lib/styles/variables";


* {
  font-family: "Nunito", serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

body {
  margin: 0;
  height: 100dvh;
  width: 100dvw;
  position: relative;
  overflow: hidden;
  background: var(--white);
  --max-page-width: 1920px;
}

h1 {
  font-size: 1.75em;
  margin-bottom: 0;
  margin-top: 0;

  @media only screen and (min-width: variables.$min-page-width-tablet) {
    font-size: 2.25em;
  }
}

h2 {
  font-size: 1.25em;
  color: var(--ci-color-1-dark);
  margin-bottom: 0;
  margin-top: 0;

  @media only screen and (min-width: variables.$min-page-width-tablet) {
    font-size: 1.75em;
  }
}

.h2-info {
  font-size: 0.8em;

  @media only screen and (min-width: variables.$min-page-width-tablet) {
    font-size: 1em;
  }
}

a {
  color: var(--ci-font-color);
  text-decoration: none;
}

p {
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-size: 1em;

  @media only screen and (min-width: variables.$min-page-width-tablet) {
    font-size: 1.2em;
  }
}

[ng-reflect-router-link],
[routerlink] {
  cursor: pointer;
}

pre {
  overflow: auto;
  border: 1px solid var(--color-border);

  code {
    margin: 0 1rem;
    display: block;
    font-size: 0.8rem;
    font-family: monospace;
  }
}


// used for all content pages with sidebar menu
.default-page-gap {
  //use padding to keep scrollbars without gap
  padding: 0.5em;

  @media only screen and (min-width: variables.$min-page-width-tablet) {
    padding: 1em;
  }
}


.vertical-line {
  border-left: 1px solid var(--ci-border-color);
  margin: 0 0.5rem;
  align-self: stretch;
}

.horizontal-line {
  border-top: 1px solid var(--ci-border-color);
  margin: 0.5em 0;
  align-self: stretch;

  &.gap-m {
    margin: 1em 0;
  }
}

.setting-label {
  font-weight: lighter;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.p-field {
  .setting-label:first-child {
    margin-top: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--color-error-light);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.tag-list {
  display: block;

  p-tag + p-tag {
    margin-left: 0.25rem;
  }
}
