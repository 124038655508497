//@use 'material-icons/iconfont/material-icons.css';
@use 'material-symbols/rounded.css';

$icon-list: (
  'account_circle',
  'add',
  'admin_panel_settings',
  'analytics',
  'arrow_back_ios',
  'chevron_left',
  'chevron_right',
  'close',
  'contact_support',
  'dashboard',
  'dataset',
  'dataset_linked',
  'delete',
  'done',
  'edit',
  'email',
  'error',
  'expand_more',
  'favorite',
  'filter_list',
  'filter_list_off',
  'folder',
  'folder_open',
  'help_center',
  'home',
  'image',
  'link',
  'list',
  'lock',
  'lock_open_right',
  'manage_accounts',
  'menu',
  'menu_book',
  'menu_open',
  'monitor_heart',
  'move_down',
  'notifications',
  'person',
  'question_mark',
  'rule',
  'settings',
  'sticky_note_2',
  'task_alt',
  'widgets',
);

@mixin use-md-icon($icon) {
  &:before {
    content: $icon;
  }
}

[class*="md-"] {
  font-family: "Material Symbols Rounded";
  font-weight: normal;
  font-style: normal;
  font-size: 1.4em;
  line-height: 1.4em;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
  font-variation-settings: 'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24;


  //overwrite pi values to ensure design is working smoothly
  &.pi {
    font-size: 1.4em;
    line-height: 1.4em;
  }

  &.p-button-icon {
    font-size: 1.2em;
    line-height: 1.1em;
  }

  &.p-tag-icon {
    font-size: var(--p-tag-icon-size);;
    line-height: var(--p-tag-icon-size);;
  }
}

[class*="md-filled-"] {
  font-variation-settings: 'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24;
}


//p-button {
//
//  [class*="md-"] {
//    font-size: 1.4em;
//    line-height: 1.4em;
//  }
//}

@each $icon in $icon-list {
  .md-#{$icon} {
    @include use-md-icon($icon)
  }
  .md-filled-#{$icon} {
    @include use-md-icon($icon)
  }
}
