.p-flex-1 {
  flex: 1;
}

.p-grid {
  form {
    width: 100%;
  }
}

.grid-wrapper {
  // avoid scrolling in grids
  overflow-x: hidden;
  //.grid {
  //  margin-right: 0;
  //  margin-left: 0;
  //}
}

p-toolbar {
  display: block;

  .p-toolbar {
    border-radius: 0;

    &.no-border {
      border: none;
    }

    &.border-top {
      border-bottom: none;
      border-left: none;
      border-right: none;
    }

    &.no-background {
      background: none;
    }

    &.invisible {
      border: 0;
      background: none;
    }

    &.underlined {
      border: none;
      border-bottom: 1px solid var(--secondary-color);
      background: none;
    }

    &.no-padding {
      padding: 0;
    }

    &.no-padding-x {
      padding-left: 0;
      padding-right: 0;
    }

    &.no-padding-y {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.no-padding-top {
      padding-top: 0;
    }

    &.no-padding-bottom {
      padding-top: 0;
    }

    &.fixed-height {
      height: 4rem;
      padding: 0.5rem 1rem;

      .p-button {
        height: 2rem;

        &.p-button-icon-only {
          width: 2rem;
        }
      }
    }

    h1,
    h2,
    h3 {
      margin: 0;
    }
  }
}

p-tag {
  max-width: 100%;

  .secondary {
    background: var(--secondary-color);
    color: var(--text-color-secondary);
  }

  .p-tag {
    max-width: 100%;

    &.outline {
      background: none;
      color: var(--text-color);
      font-weight: normal;

      &.p-tag-info {
        border: 1px solid var(--color-info);
      }
    }

    .p-tag-value {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

p-messages,
p-message {
  //enable margins for messages
  display: block;

  .p-inline-message {
    width: 100%;

    &.small {
      padding: 0.25rem 0.25rem;

      .p-inline-message-text {
        font-size: 0.75rem;
      }
    }
  }

  .p-messages,
  .p-message {
    &.no-margin {
      .p-message {
        margin: 0;
      }
    }

    &.padding-xs {
      .p-message-wrapper {
        padding: 0.25rem;
      }
    }
  }

  &[severity='none'] {
    border: solid var(--surface-700);
    border-width: 0 0 0 3px;
    background: var(--surface-100);
  }
}

.p-field {
  &.disabled {
    color: var(--disabled-color);
  }

  small {
    &.error {
      color: var(--color-error);
    }
  }

  .p-error {
    display: none;
  }

  &.invalid {
    .p-error {
      display: block;
    }
  }

  &.valid {
    .p-inputtext {
      border-color: var(--color-success);

      &:focus {
        border-color: var(--color-success);
        box-shadow: 0 0 0 0.2rem var(--green-100);
      }
    }

    .p-checkbox .p-checkbox-box {
      border-color: var(--color-success);
      background: var(--color-success);

      &:focus {
        border-color: var(--color-success);
        box-shadow: 0 0 0 0.2rem var(--green-100);
      }
    }
  }

  + .p-field {
    margin-top: 0.5rem;
  }
}

p-card {
  display: block;

  .p-card {
    box-shadow: 0px 0px 2px var(--secondary-color);
    border: 1px solid transparent;

    &.interactive {
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 2px var(--primary-color);
        border-color: var(--primary-color);
      }
    }

    &.info-card {
      box-shadow: none;
      background-color: var(--white);

      &.interactive {
        &:hover {
          box-shadow: none;
          border: 1px solid var(--primary-color);
        }
      }
    }

    .p-card-header {
      p-header {
        padding-bottom: 0;
      }
    }

    .p-card-content {
      padding: 0;

      h1,
      h2,
      h3,
      h4 {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &.teaser {
    p-header {
      display: block;
      padding: 1rem;

      .card-pre-header {
      }

      .card-header {
        font-size: 1.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &.stretch {
    display: flex;
    height: 100%;

    .p-card {
      flex: 1;
      display: flex;
      align-content: stretch;
      flex-direction: column;

      .p-card-body {
        display: flex;
        align-content: stretch;
        flex-direction: column;
        flex: 1;

        .p-card-content {
          flex: 1;
        }
      }
    }
  }

  // do we really need this (use stretch instead)?
  &.flex {
    display: flex;
    align-content: stretch;

    .p-card {
      display: flex;
      align-content: stretch;
      flex: 1;

      .p-card-body {
        display: flex;
        align-content: stretch;
        flex-direction: column;
        flex: 1;

        .p-card-content {
          display: flex;
          align-content: center;
          flex: 1;
          align-items: center;
        }
      }
    }

    &.centered {
      .p-card {
        .p-card-body {
          align-items: center;
        }
      }
    }
  }
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label {
  top: 0 !important;
  background: white;
  padding: 0 0.5rem;
}

p-dropdown {
  display: flex;
  flex-direction: column;

  .p-dropdown {
    // set height for empty dropdowns
    min-height: 2.5rem;
    width: 100%;
  }
}

.p-inputtext {
  &.no-focus {
    &:focus {
      outline: inherit;
      border: 1px solid var(--primary-color);
      box-shadow: inherit;
    }
  }
}

p-multiselect {
  display: flex;
  flex-direction: column;

  .p-multiselect-label-container {
    height: 2.5rem;

    .p-chip .p-chip-text {
      line-height: 1rem;
    }
  }
}

.p-colorpicker-overlay-panel {
  background: #fff;
}

.p-button {
  &.no-focus {
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  &.no-wrap {
    .p-button-label {
      white-space: nowrap;
    }
  }

  &.slim {
    width: auto;
    padding: 0.3rem 0.1rem;
  }

  &.dark {
    .p-button-label,
    .p-button-icon {
      color: var(--white);
    }
  }

  &.p-button-icon-only {
    &.p-button-rounded {
      overflow: visible;

      .p-badge {
        position: absolute;
        top: -0.25rem;
        right: -0.25rem;
      }

      &.p-button-sm {
        height: 1.5rem;
        width: 1.5rem;
        padding: 0.69rem;
        line-height: 0;
        text-align: center;
        background: var(--white);

        .p-button-icon {
          font-size: 0.65rem;
        }

        &:focus,
        &:hover {
          background: var(--primary-color-lighter);
        }
      }
    }
  }

  &.input-group-button {
    border-radius: 0;
  }

  &.input-group-button-end {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }

  //white button
  &.p-button-white {
    background: var(--white);
  }

  // button on black background
  &.white {
    color: var(--white);

    &:focus {
      box-shadow: 0 0 0 0.2rem var(--white-200);
    }
  }
}

.p-overlaypanel {
  max-height: calc(100vh - 1rem);

  .p-overlaypanel-content {
    max-width: 100vw;
    margin: 0.2rem;
  }

  &.growable {
    .p-overlaypanel-content {
      font-size: 0.8rem;
      max-height: 80vh;
      overflow: auto;
    }
  }

  &.no-padding {
    .p-overlaypanel-content {
      margin: 0;
      padding: 0;
    }
  }

  &.padding-sm {
    .p-overlaypanel-content {
      margin: 0;
      padding: 0.5rem;
    }
  }

  &.transparent {
    opacity: 0.8 !important;
    background: rgba(255, 255, 255, 0.6);
  }

  &.footer {
    .p-overlaypanel-content {
      .footer-button-right {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        background: #fff;
        box-shadow: 0px 0px 1rem #fff;
      }
    }
  }

  &.large-content {
    .p-overlaypanel-content {
      width: 30rem;
    }

    @media only screen and (min-width: 768px) {
      .p-overlaypanel-content {
        width: 50vw;
        max-width: 768px;
      }
    }
  }
}

.p-toast {
  &.custom-tpl {
    .p-toast-message {
      .p-toast-icon-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
      }
    }
  }
}

.p-dialog {
  &.auto-size {
    max-width: 90vw;
  }
}

p-virtualScroller {
  .p-virtualscroller {
    &.item-border {
      .p-virtualscroller-item {
        border-top: 1px solid var(--gray-200);
        border-bottom: 1px solid var(--gray-200);

        + .p-virtualscroller-item {
          border-top: none;
        }
      }
    }
  }
}

.p-orderlist {
  &.no-buttons {
    .p-orderlist-controls {
      display: none;
    }
  }

  &.no-padding {
    .p-orderlist-list {
      padding: 0;
    }
  }

  &.no-border {
    .p-orderlist-list {
      border: none;
    }
  }
}

.p-listbox {
  &.no-border {
    //.p-listbox-list {
    border: none;
    //}
  }
}

.p-tree {
  &.no-style {
    background: transparent;
    border: none;
    padding: 0;
    color: var(--text-color);
  }

  .disabled {
    color: gray;
  }
}

p-inputnumber {
  &.p-inputwrapper {
    display: flex;
    flex-direction: column;
  }
}

p-calendar {
  display: flex;
  flex-direction: column;
}

p-dynamicdialog {
  .p-dialog-mask {
    z-index: 1000;
  }
}

p-inputswitch {
  display: flex;

  .p-inputswitch {
    align-self: center;

    &.sm {
      width: 2rem;
      height: 1rem;

      .p-inputswitch-slider:before {
        width: 0.8rem;
        height: 0.8rem;
        left: 0.1rem;
        margin-top: -0.4rem;
      }

      &.p-inputswitch-checked .p-inputswitch-slider {
        &:before {
          transform: translateX(1rem);
        }
      }
    }

    &.secondary {
      &.p-inputswitch-checked .p-inputswitch-slider {
        background: var(--secondary-color);
      }
    }
  }
}

.p-inputgroup {
  .p-inputgroup-addon {
    &.active {
      color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }

  &.invalid {
    .p-inputtext {
      border-color: var(--color-error);
    }
  }

  .p-inputtext {
    //aligns height with buttons
    height: 2.357em;
  }
}

// fixes missing margin on the right site of input elements when using an icon and there is not enough space
.p-input-icon-left > .p-inputtext {
  margin-right: -1rem;
  max-width: 100%;
}

.p-fluid .p-inputtext {
  width: 100%;
  // override to avoid increasing with of dynamic parent elements
  display: flex;
}

p-menu {
  .p-menu {
    &.auto-width {
      background: transparent;
      border: none;
      padding: 0;
    }

    &.invisible {
      width: auto;
    }

    &.dark {
      .p-menuitem {
        .p-menuitem-content {
          .p-menuitem-icon {
            color: var(--white);
          }

          .p-menuitem-text {
            color: var(--white);
          }

          &:hover {
            .p-menuitem-icon {
              color: var(--color-dark);
            }

            .p-menuitem-text {
              color: var(--color-dark);
            }
          }
        }
      }
    }
  }
}

.p-fileupload .p-fileupload-buttonbar {
  // space between buttons
  display: flex;
  justify-content: space-between;
}

//.p-tabview {
//  &.content-scrollable {
//
//  }
//}

p-tabview {
  &[styleclass='full-height'] {
    flex: 1;
    overflow: hidden;
  }

  .p-tabview {
    &.no-background {
      .p-tabview-nav,
      .p-tabview-nav-link,
      .p-tabview-panels {
        background: none;
      }
    }

    &.full-height {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .p-tabview-panels {
        max-height: 100%;
        overflow: auto;
        flex: 1;

        p-tabpanel {
          &.p-tabview-panel {
            &:not([hidden]) {
              max-height: 100%;
              display: flex;
              align-self: stretch;
            }
          }
        }
      }
    }

    &.custom-header {
      .p-tabview-nav li .p-tabview-nav-link {
        padding: 0;
      }
    }
  }
}

.p-overlay-badge {
  // adds small size to badges
  &.badge-small {
    .p-badge {
      font-size: 0.7rem;
      font-weight: 500;
      min-width: 1rem;
      height: 1rem;
      line-height: 0.9rem;
    }
  }

  &.badge-outline {
    .p-badge {
      background: none;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
  }

  &.badge-pos-70 {
    .p-badge {
      transform: translate(70%, -70%);
    }
  }

  &.badge-pos-80 {
    .p-badge {
      transform: translate(80%, -80%);
    }
  }
}
